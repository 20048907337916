export class HeaderService {
    header;
    links;
    rootEl;
    DEBOUNCE_TIME = 50;
    timeoutId = null;

    constructor() {
        this.header = document.querySelector('.js-header');
        this.links = Array.from(this.header.querySelectorAll('.js-header-link'));
        this.rootEl = document.querySelector('.js-root');
        this.init();
    }

    init() {
        this.initEvents();
        const hash = location.hash;
        if (hash) {
            const link = document.getElementById(`${hash.replace('#', '')}-link`);
            this.setActiveLink(link);
        }
    }

    setActiveLink(link) {
        this.links.forEach((link) => link.classList.remove('active'));
        link.classList.add('active');
    }

    scrollHandler() {
        if (this.timeoutId) {
            clearTimeout(this.timeoutId);
        }

        this.timeoutId = setTimeout(() => {
            if (window.scrollY) {
                this.header.classList.add('fixed');
                this.rootEl.classList.add('fixed-header');
            } else {
                this.header.classList.remove('fixed');
                this.rootEl.classList.remove('fixed-header');
            }
        }, this.DEBOUNCE_TIME);
    }

    initEvents() {
        this.links.forEach((link) => {
            link.addEventListener('click', () => this.setActiveLink(link));
        });
        document.addEventListener('scroll', this.scrollHandler.bind(this));
    }
}

const headerService = new HeaderService();

export default headerService;
