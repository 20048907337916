import configService from "./config-service";
class GamesListService {
    promoCode;
    promoIsValid;

    constructor() {
        this.games = {
            top: [],
            new: [],
        };
        this.selectedGame = null;
        this.init();
    }

    init() {

    }

    buildGamesList() {
        const gamesList = document.querySelector('.js-games-list');
        const topGames = gamesList.querySelector('.js-top-games');
        const newGames = gamesList.querySelector('.js-new-games');
        const selectedGame = gamesList.querySelector('.js-selected-game');
        const selectedGameTitle = selectedGame.querySelector('.js-title');
        const selectedGameDescription = selectedGame.querySelector('.js-description');
        const selectedGameTime = selectedGame.querySelector('.js-time');
        const selectedGameAge = selectedGame.querySelector('.js-age');
        const selectedGamePlayers = selectedGame.querySelector('.js-players');
        const selectedGameBg = selectedGame.querySelector('.js-selected-game-bg');
        const selectedGameTrailerBtn = selectedGame.querySelector('.js-trailer-btn');
        const selectedGamePlayBtn = selectedGame.querySelector('.js-play-btn');
        const topGamesItems = topGames.getElementsByTagName('li');
        const newGamesItems = newGames.getElementsByTagName('li');

        const selectedGamePopup = document.querySelector('.js-game-selected-popup');
        const selectedGamePopupCloseBtn = selectedGamePopup.querySelector('.js-close-btn');
        const selectedGamePopupPlayBtn = selectedGamePopup.querySelector('.js-play-btn');
        const selectedGamePopupHeaderText = selectedGamePopup.querySelector('.js-game-name');
        const selectedGamePopupTitle = selectedGamePopup.querySelector('.js-game-title');
        const selectedGamePopupBannerWrapper = selectedGamePopup.querySelector('.js-game-img');
        const selectedGamePopupBanner = selectedGamePopupBannerWrapper.querySelector('img');
        const selectedGamePopupTime = selectedGamePopup.querySelector('.js-time');
        const selectedGamePopupAge = selectedGamePopup.querySelector('.js-age');
        const selectedGamePopupPlayers = selectedGamePopup.querySelector('.js-players');
        const selectedGamePopupDescription = selectedGamePopup.querySelector('.js-description');
        const selectedGamePopupTrailerBtn = selectedGamePopup.querySelector('.js-trailer-btn');

        this.selectedGame = this.games.top.length ? this.games.top[0] : this.games.new[0] ?? null;

        const closeSelectedGamePopup = () => {
            selectedGamePopup.style.display = 'none';
            document.body.classList.remove('fixed');
            const games = document.getElementById('games');
            const topPos = games.getBoundingClientRect().top;
            window.scrollTo({
                top: topPos,
                behavior: 'smooth'
            })
        };

        const buildSelectedGame = (game) => {
            if (!game) {
                return;
            }
            // устанавливаем цвет текста в описании игры
            if (game.darkText) {
                document.documentElement.style.setProperty('--game-description-color', '#000');
            } else {
                document.documentElement.style.setProperty('--game-description-color', '#fff');
            }

            // настраиваем кнопку показа трейлера
            if (game.trailerVideo) {
                selectedGameTrailerBtn.style.display = 'flex';
                selectedGamePopupTrailerBtn.style.display = 'flex';
                selectedGameTrailerBtn.dataset.video = game.trailerVideo.url;
                selectedGamePopupTrailerBtn.dataset.video = game.trailerVideo.url;
            } else {
                selectedGameTrailerBtn.style.display = 'none';
                selectedGamePopupTrailerBtn.style.display = 'none';
                selectedGameTrailerBtn.dataset.video = '';
                selectedGamePopupTrailerBtn.dataset.video = '';
            }

            // ставим видео-баннер
            const videoBanner = selectedGameBg.querySelector('.js-video-banner');
            if (game.galleryBannerVideo && window.innerWidth >= 768) {
                videoBanner.src = game.galleryBannerVideo.url;
                videoBanner.style.display = 'block';
                videoBanner.play();
            } else {
                videoBanner.pause();
                videoBanner.style.display = 'none'
            }

            if (game.galleryBannerImage) {
                selectedGameBg.style.backgroundImage = `url(${game.galleryBannerImage.url})`;
            } else {
                selectedGameBg.style.backgroundImage = '';
            }

            if (game.previewImage) {
                selectedGamePopupBannerWrapper.style.display = 'block';
                selectedGamePopupBanner.src = game.previewImage.url;
            } else {
                selectedGamePopupBannerWrapper.style.display = 'none';
                selectedGamePopupBanner.src = '';
            }

            // ставим тайтл свг или текст
            const titleImg = selectedGameTitle.querySelector('.js-image');
            const titleText = selectedGameTitle.querySelector('.js-text');
            selectedGamePopupHeaderText.innerHTML = game.name;
            selectedGamePopupTitle.innerHTML = game.name;
            if (game.logoImage) {
                titleImg.src = game.logoImage.url;
                titleText.style.display = 'none';
                titleImg.style.display = 'block';
            } else {
                titleText.innerHTML = game.name;
                titleText.style.display = 'block';
                titleImg.style.display = 'none';
            }

            // описание игры
            if (game.description) {
                selectedGameDescription.innerHTML = game.description;
                selectedGamePopupDescription.innerHTML = game.description;
                selectedGameDescription.style.display = 'block';
                selectedGamePopupDescription.style.display = 'block';
            } else {
                selectedGameDescription.innerHTML = '';
                selectedGamePopupDescription.innerHTML = '';
                selectedGameDescription.style.display = 'none';
                selectedGamePopupDescription.style.display = 'none';
            }

            // время игры
            const timeText = selectedGameTime.querySelector('.js-text');
            const popupTimeText = selectedGamePopupTime.querySelector('.js-text');
            if (game.averageGameTime) {
                timeText.innerHTML = game.averageGameTime;
                popupTimeText.innerHTML = game.averageGameTime;
                selectedGameTime.style.display = 'flex';
                popupTimeText.style.display = 'flex';
            } else {
                timeText.innerHTML = '';
                popupTimeText.innerHTML = '';
                selectedGameTime.style.display = 'none';
                popupTimeText.style.display = 'none';
            }

            // рекомендуемый возраст
            const ageText = selectedGameAge.querySelector('.js-text');
            const popupAgeText = selectedGamePopupAge.querySelector('.js-text');
            if (game.age) {
                ageText.innerHTML = game.age;
                popupAgeText.innerHTML = game.age;
                selectedGameAge.style.display = 'flex';
                popupAgeText.style.display = 'flex';
            } else {
                ageText.innerHTML = '';
                popupAgeText.innerHTML = '';
                selectedGameAge.style.display = 'none';
                popupAgeText.style.display = 'none';
            }

            // рекомендуемое кол-во игроков
            const playersText = selectedGamePlayers.querySelector('.js-text');
            const popupPlayersText = selectedGamePopupPlayers.querySelector('.js-text');
            if (game.numberOfPlayers) {
                playersText.innerHTML = game.numberOfPlayers;
                popupPlayersText.innerHTML = game.numberOfPlayers;
                selectedGamePlayers.style.display = 'flex';
                popupPlayersText.style.display = 'flex';
            } else {
                playersText.innerHTML = '';
                popupPlayersText.innerHTML = '';
                selectedGamePlayers.style.display = 'none';
                popupPlayersText.style.display = 'none';
            }

            const text = selectedGamePlayBtn.querySelector('.js-btn-text');
            if (game.inSubscriptions) {
                text.innerHTML = 'Играть';
            } else {
                text.innerHTML = 'Играть бесплатно';
            }
        };

        const setActiveGameItem = (item, game) => {
            buildSelectedGame(game);
            Array.from(topGamesItems).forEach((holder) => holder.classList.remove('active'));
            Array.from(newGamesItems).forEach((holder) => holder.classList.remove('active'));
            item.classList.add('active');
            if (window.innerWidth < 768) {
                selectedGamePopup.style.display = 'block';
                document.body.classList.add('fixed');
            }
        };

        const buildTopGames = () => {
            this.games.top.forEach((game, index) => {
                const item = topGamesItems[index];
                item.querySelector('img').src = game.previewImage.url;
                item.style.display = 'block';
                item.addEventListener('click', () => setActiveGameItem(item, game));
            })
        };

        const buildNewGames = () => {
            this.games.new.forEach((game, index) => {
                const item = newGamesItems[index];
                item.querySelector('img').src = game.previewImage.url;
                item.style.display = 'block';
                item.addEventListener('click', () => setActiveGameItem(item, game));
            })
        };

        buildSelectedGame(this.selectedGame);
        buildTopGames();
        buildNewGames();

        selectedGamePopupCloseBtn.addEventListener('click', () => {
            closeSelectedGamePopup();
        });
        selectedGamePopupPlayBtn.addEventListener('click', () => {
            closeSelectedGamePopup();
        });
        selectedGamePopupTrailerBtn.addEventListener('click', () => {
            closeSelectedGamePopup();
        });
    }

    /**
     * Загружаем список игр
     * @param category
     */
    getGamesList(category) {
        const getPromoUrl = `${configService.getConfig()?.ADMIN_BASE_URL ?? ''}api/app/landing/category?category=${category}`;
        return new Promise((resolve, reject) => {
            try {
                fetch(getPromoUrl, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then( (response) => response.json())
                    .then(data => {
                        this.games[category] = data.response;
                        resolve(data.response);
                    });
            } catch (error) {
                console.error('Error:', error);
                reject(error);
            }
        });
    }
}

const gamesListService = new GamesListService();

export default gamesListService;
