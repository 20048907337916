import Swiper, {Autoplay, EffectFade, FreeMode, Navigation, Thumbs} from 'swiper';

Swiper.use([Navigation, EffectFade, Thumbs, Autoplay, FreeMode]);

export class InstructionModal {
    modal;
    backBtn;
    showPcBtn;
    showAndroidBtn;
    pcSteps;
    defaultSteps;
    androidSteps;
    stepIsVisible;
    constructor() {
        this.modal = document.querySelector('.js-instruction-modal');
        this.backBtn = this.modal.querySelector('.js-back-btn');
        this.showPcBtn = this.modal.querySelector('.js-show-pc');
        this.showAndroidBtn = this.modal.querySelector('.js-show-android');
        this.defaultSteps = this.modal.querySelector('.js-default-content');
        this.pcSteps = this.modal.querySelector('.js-pc-content');
        this.androidSteps = this.modal.querySelector('.js-android-content');
        this.stepIsVisible = false;

        this.init();
    }

    show() {
        this.modal.style.display = 'block';
    }

    hide() {
        this.modal.style.display = 'none';
    }

    bindEvents() {
        this.backBtn.addEventListener('click', () => {
            if (!this.stepIsVisible) {
                this.hide();
            } else {
                this.stepIsVisible = false;
                this.pcSteps.style.display = 'none';
                this.androidSteps.style.display = 'none';
                this.defaultSteps.style.display = 'block';
            }
        });
        this.showPcBtn.addEventListener('click', () => {
            this.stepIsVisible = true;
            this.pcSteps.style.display = 'block';
            this.defaultSteps.style.display = 'none';
        });
        this.showAndroidBtn.addEventListener('click', () => {
            this.stepIsVisible = true;
            this.androidSteps.style.display = 'block';
            this.defaultSteps.style.display = 'none';
        });
    }

    init() {
        this.bindEvents();
    }
}

const instructionModal = new InstructionModal();

export default instructionModal;
