import '@/scss/styles.scss';
import configService from "./plugins/config-service";
import headerService from "@/js/plugins/fixed-header";
import gamesListService from "@/js/plugins/games-list-service";
import videoPopupService from "@/js/plugins/video-popup-service";
import slidersService from "@/js/plugins/sliders-service";
import SubscriptionForm from "@/js/plugins/subscription-form";
import instructionModal from "@/js/plugins/instruction-modal";
import giftService from "@/js/plugins/gift-service";

document.addEventListener("DOMContentLoaded", async function(event) {
    const init = () => {
        const tvButtonLinks = document.querySelectorAll('.js-play-btn');
        const copyWrapper = document.querySelectorAll('.js-copy-wrapper');
        const chromeAlert = document.querySelector('.js-chrome-alert');
        const closeChromeAlertBtn = chromeAlert.querySelector('.js-close-alert');

        videoPopupService.init();
        slidersService.init();
        giftService.init();
        headerService.init();
        const fetchTopGames = gamesListService.getGamesList('top');
        const fetchNewGames = gamesListService.getGamesList('new');
        Promise.all([fetchTopGames, fetchNewGames]).then(() => {
            gamesListService.buildGamesList();
            if (window.innerWidth < 768) {
                slidersService.initGameMobileSliders();
            }
        });

        copyWrapper.forEach((element)=> {
            copyingText(element);
        });

        const getBrowserName = () => {
            const nAgt = navigator.userAgent;
            let browserName='';
            // In Opera 15+, the true version is after "OPR/"
            if (( nAgt.indexOf("OPR/")) !== -1) {
                browserName = "Opera";
            }
            // In older Opera, the true version is after "Opera" or after "Version"
            else if (( nAgt.indexOf("Opera")) !== -1) {
                browserName = "Opera";
            }
            // In MSIE, the true version is after "MSIE" in userAgent
            else if (( nAgt.indexOf("MSIE")) !== -1) {
                browserName = "Microsoft Internet Explorer";
            }
            // In Chrome, the true version is after "Chrome"
            else if (( nAgt.indexOf("Chrome")) !== -1) {
                browserName = "Chrome";
            }
            // In Safari, the true version is after "Safari" or after "Version"
            else if (( nAgt.indexOf("Safari")) !== -1) {
                browserName = "Safari";
            }
            // In Firefox, the true version is after "Firefox"
            else if (( nAgt.indexOf("Firefox")) !== -1) {
                browserName = "Firefox";
            }
            return browserName;
        };

        if (getBrowserName() !== 'Chrome' && (window.innerWidth > 767)) {
            chromeAlert.style.display = 'block';
        }

        closeChromeAlertBtn.addEventListener('click', () => {
            chromeAlert.style.display = 'none';
        });

        /**
         *копирование текста в буфер обмена
         */
        function copyingText(element) {
            const textElement = element.querySelector('.js-copy-text');
            element.addEventListener('click', function () {
                const text = textElement.textContent;
                const textArea = document.createElement("textarea");
                textArea.style.position = "fixed";
                textArea.style.opacity = "0";
                textArea.value = text;
                document.body.appendChild(textArea);
                textArea.select();
                document.execCommand('copy');
                document.body.removeChild(textArea);
                this.classList.add('copied');

                setTimeout(() => {
                    this.classList.remove('copied');
                }, 2000);
            });
        }

        const tvLink = `${configService.getConfig()?.TV_BASE_URL}${window.location.search}`;

        tvButtonLinks.forEach((link) => {
            link.setAttribute('href', tvLink ?? '');
            
            link.addEventListener('click', (e) => {
                if ((window.innerWidth < 768)) {
                    e.preventDefault();
                    instructionModal.show();
                }
            });
        });

        const formWrapper = document.querySelectorAll('.js-subscribe-form-wrapper');
        Array.from(formWrapper).forEach((el) => new SubscriptionForm(el))
    }

    await configService.loadRuntimeConfig();
    await init();
});
