
// Сервис по работе с конфигами
class ConfigService {
    runtimeConfig = null;

    /**
     * Загружаем рантайм конфиг
     */
    async loadRuntimeConfig() {
        try {
            let response = await fetch('runtime-config.json', {
                baseURL: '/',
                params: {
                    t: new Date().getTime(), // для предотвращения кэширования
                },
            });

            response = await response.json();

            if (typeof response !== 'object' || !response) {
                this.runtimeConfig = null;
                return Promise.resolve(true);
            }

            this.runtimeConfig = response;
        } catch (e) {
            console.log(e);
        }

        return Promise.resolve(true);
    }

    /**
     * Получить конфиг
     * @returns {null|{ADMIN_BASE_URL: string, MOBILE_BASE_URL: string}}
     */
    getConfig() {
        return this.runtimeConfig ? this.getRuntimeConfig() : this.getConfigByEnv();
    }

    /**
     * Конфиг приложения
     */
    getRuntimeConfig() {
        return this.runtimeConfig;
    }

    /**
     * Получить конфиг из .env
     * @private
     */
    getConfigByEnv() {
        return {
            ADMIN_BASE_URL: 'https://admin.partystation.tv/',
            MOBILE_BASE_URL: 'https://mobile.partystation.tv/',
            TV_BASE_URL: 'https://pstv.ru/tv/',
            GAME_SERVER_URL: 'wss://game-server.partystation.tv/'
        };
    }
}

const configService = new ConfigService();

export default configService;
