import Swiper from 'swiper';
import {Autoplay, EffectFade, FreeMode, Navigation, Thumbs} from 'swiper'

Swiper.use([Navigation, EffectFade, Thumbs, Autoplay, FreeMode]);

export class SlidersService {
    feedbackSlider;
    mainSlider;
    mainThumbs;
    helpMobileSlider;
    topGamesMobileSlider;
    newGamesMobileSlider;
    constructor() {}
    initFeedbackSlider() {
        const desktopSpaceBetween = (200 / (3840 * 0.01)) * (window.innerWidth / 100);
        this.feedbackSlider = new Swiper('.js-feedback-slider', {
            slidesPerView: 'auto',
            rewind: true,
            spaceBetween: 24,
            breakpoints: {
                768: {
                    slidesPerView: 2,
                    grabCursor: true,
                    spaceBetween: desktopSpaceBetween,
                    navigation: {
                        nextEl: '.js-feedback-next',
                        prevEl: '.js-feedback-prev',
                    },
                }
            },
        });
    }

    initMainSlider() {
        this.mainThumbs = new Swiper('.js-thumbs-slider', {
            slidesPerView: 'auto',
        });
        this.mainSlider = new Swiper('.js-main-slider', {
            effect: "fade",
            rewind: true,
            speed: 500,
            fadeEffect: {
                crossFade: true,
            },
            thumbs: {
                swiper: this.mainThumbs,
            },
            autoplay: {
                delay: 5000,
                disableOnInteraction: false
            },
        });
    }

    initHelpSliderOnMobile() {
        this.helpMobileSlider = new Swiper('.js-help-slider', {
            slidesPerView: 'auto',
            spaceBetween: 12,
            allowTouchMove: true,
            touchMoveStopPropagation: true,
            touchEventsTarget: 'container',
            freeMode: true,
            autoplay: true,
        });
    }

    initGameMobileSliders() {
        const params = {
            slidesPerView: 'auto',
            spaceBetween: 12,
        };
        this.topGamesMobileSlider = new Swiper('.js-top-games-slider', params);
        this.newGamesMobileSlider = new Swiper('.js-new-games-slider', params);
    }

    init() {
        this.initFeedbackSlider();
        this.initMainSlider();
        if (window.innerWidth < 768) {
            this.initHelpSliderOnMobile();
        }
    }
}

const slidersService = new SlidersService();

export default slidersService;
