class VideoPopupService {
    constructor() {
        this.videoClickHolder = document.querySelectorAll('.js-video-holder');
        this.videoPopup = document.querySelector('.js-popup');
        this.video = document.querySelector('.js-video');
        this.videoLoader = document.querySelector('.js-video-loader');
        this.videoUrl = '';
    }

    bindEvents() {
        const playVideo = () => {
            this.video.play();
            this.videoLoader.style.display = 'none';
        }

        Array.from(this.videoClickHolder).forEach((holder) => {
            holder.addEventListener('click', () => {
                this.videoUrl = holder.dataset.video
                this.video.src = this.videoUrl;
                this.video.load();
                this.video.addEventListener('canplaythrough', playVideo);
                this.videoPopup.style.display = 'flex';
            });
        });

        this.video.addEventListener('click', (e) => {
            e.stopPropagation();
        });

        this.videoPopup.addEventListener('click', () => {
            this.video.removeEventListener('canplaythrough', playVideo);
            this.video.load();
            this.video.pause();
            this.video.currentTime = 0;
            this.videoPopup.style.display = 'none';
        });
    }

    init() {
        this.bindEvents();
    }
}

const videoPopupService = new VideoPopupService();

export default videoPopupService;
