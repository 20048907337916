import configService from "@/js/plugins/config-service";

const SubscriptionForm = class SubscriptionForm {
    HAS_ERROR = 'has-error';
    SUBSCRIBE_SUCCESS = 'has-success';
    SUCCESS_BTN_TEXT = 'Вы подписались!';
    DEFAULT_BTN_TEXT = 'Подписаться';
    constructor(wrapper) {
        this.subscribeFormWrapper = wrapper;
        this.subscribeForm = this.subscribeFormWrapper.querySelector('.js-subscribe-form');
        this.subscribeBtn = this.subscribeFormWrapper.querySelector('.js-subscription-btn');
        this.emailField = this.subscribeFormWrapper.querySelector('.js-email');
        this.errorText = this.subscribeFormWrapper.querySelector('.js-error-text');
        this.subscribeUrl = `${configService.getConfig()?.ADMIN_BASE_URL ?? ''}api/subscriber/create`;
        this.handleSubscribeSuccessTimeoutId = null;

        this.init();
    }

    /**
     * Показать ошибку
     * @param error
     */
    showError(error) {
        if (typeof error.message.email[0] !== 'undefined') {
            this.errorText.innerHTML = error.message.email[0];
        }
        this.subscribeFormWrapper.classList.add(this.HAS_ERROR);
    };

    clearError() {
        this.subscribeFormWrapper.classList.remove(this.HAS_ERROR);
    };

    /**
     * Обработать успешную подписку
     */
    handleSubscribeSuccess() {
        this.subscribeFormWrapper.classList.add(this.SUBSCRIBE_SUCCESS);
        this.subscribeBtn.innerHTML = this.SUCCESS_BTN_TEXT;

        if (this.subscribeForm.dataset.giftId) {
            const event = new Event('gift-success');
            document.dispatchEvent(event);
        }

        if (this.handleSubscribeSuccessTimeoutId) {
            clearTimeout(this.handleSubscribeSuccessTimeoutId);
        }

        this.handleSubscribeSuccessTimeoutId = setTimeout(() => {
            this.subscribeFormWrapper.classList.remove(this.SUBSCRIBE_SUCCESS);
        }, 5000);
    };

    bindEvents() {
        this.emailField.addEventListener('focus', () => {
            this.clearError();
        });

        /**
         * Форма подписки
         */
        this.subscribeForm.addEventListener('submit', (e) => {
            e.preventDefault();
            this.clearError();
            this.subscribeFormWrapper.classList.remove(this.SUBSCRIBE_SUCCESS);
            try {
                const params = { email: this.emailField.value, language: 'ru' };
                if (this.subscribeForm.dataset.giftId) {
                    params.giftOfferId = this.subscribeForm.dataset.giftId;
                }
                fetch(this.subscribeUrl, {
                    method: 'POST',
                    body: JSON.stringify(params),
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then( (response) => response.json())
                    .then(data => {
                        if (data.error) {
                            this.showError(data.error);
                        } else {
                            this.handleSubscribeSuccess();
                        }
                    });
            } catch (error) {
                console.error('Error:', error);
                this.showError(error);
            }
        });
    };

    init() {
        this.bindEvents();
    };
}

export default SubscriptionForm;
