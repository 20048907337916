import configService from "@/js/plugins/config-service";

export class GiftService {
    holder;
    closeBtn;
    expandBtn;
    EXPAND_CLASS = 'opened';
    SUCCESS_CLASS = 'has-success';
    SUCCESS_TITLE = 'Вы успешно подписались!';
    giftIsAvailable;
    giftData;
    advantages;
    formHolder;
    title;
    constructor() {
        this.holder = document.querySelector('.js-gift-offer');
        this.closeBtn = this.holder.querySelector('.js-close-btn');
        this.expandBtn = this.holder.querySelector('.js-expand-btn');
        this.advantages = this.holder.querySelector('.js-advantages');
        this.giftIsAvailable = false;
        this.giftData = null;
        this.formHolder = this.holder.querySelector('.js-subscribe-form');
        this.title = this.holder.querySelector('.js-title');
    }

    /**
     * Записать куку
     * @param name
     * @param date
     * @param value
     */
    setCookie(name, value, date) {
        try {
            document.cookie = `${name}=${value}; expires="${date.toUTCString()}"; path=/`;
        } catch (e) {}
    }

    /**
     * Значение куки по имени
     * @param name
     */
    getCookie(name)
    {
        let value = "; " + document.cookie;
        let parts = value.split("; " + name + "=");
        if (parts.length === 2) {
            return parts.pop().split(";").shift();
        }
        return undefined;
    }

    hideHolder() {
        const date = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
        this.setCookie('gift-offer-id', this.giftData.id, date);
        this.holder.style.display = 'none';
    }

    expandHolder() {
        this.holder.classList.add(this.EXPAND_CLASS);
    }

    fillGift() {
        this.holder.style.display = 'block';
        const listEl = this.advantages.getElementsByTagName('li');
        Array.from(listEl).forEach((el, index) => {
            const text = el.querySelector('.js-text');
            text.innerHTML = index ? this.giftData.offer2 : this.giftData.offer1;
        });
        this.formHolder.dataset.giftId = this.giftData.id;
    }

    bindEvents() {
        this.closeBtn.addEventListener('click', this.hideHolder.bind(this));
        this.expandBtn.addEventListener('click', this.expandHolder.bind(this));
        document.addEventListener('gift-success', () => {
            this.holder.classList.add(this.SUCCESS_CLASS);
            this.title.innerHTML = this.SUCCESS_TITLE;
            setTimeout(() => this.hideHolder(), 5000);
        });
    }

    fetchGift() {
        try {
            fetch(`${configService.getConfig()?.ADMIN_BASE_URL ?? ''}api/app/gift-offer/active`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then( (response) => response.json())
                .then(data => {
                    this.giftData = data.response;
                    if (!this.giftData) {
                        return;
                    }
                    this.fillGift();
                });
        } catch (error) {
            console.error('Error:', error);
        }
    }

    init() {
        if (!this.getCookie('gift-offer-id')) {
            this.bindEvents();
            this.fetchGift();
        }
    }
}

const giftService = new GiftService();

export default giftService;
